export const DeptTypeEnum = {
    HEAD: 1,
    REPOSITORY: 2,
    STORE: 3,
    properties: {
        // desc：机构类型描述，status：类型，tagEffect：tag标签颜色，shortDesc：机构类型简称
        1: { desc: '总部', status: 1, tagEffect: 'warning', shortDesc: '总' },
        2: { desc: '配送中心', status: 2, tagEffect: '', shortDesc: '配' },
        3: { desc: '门店', status: 3, tagEffect: 'success', shortDesc: '店' },
    },
};
